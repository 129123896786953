@charset "utf-8";

///////////////////////////////////////////////////////////
// font
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;

.u-font {
  &-w300 {
    font-weight: 300;
  }

  &-w400 {
    font-weight: 400;
  }

  &-w500 {
    font-weight: 500;
  }

  &-w600 {
    font-weight: 600;
  }

  &-w700 {
    font-weight: 700;
  }

  &-w900 {
    font-weight: 900;
  }

  &-size24 {
    font-size: clamp_size(18, 24);
  }
}