@charset "utf-8";

///////////////////////////////////////////////////////////
// list
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-list {
  position: relative;
  font-size: inherit;
  list-style-type: disc;
  padding-left: 1.53rem;
}
