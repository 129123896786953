@charset "utf-8";

///////////////////////////////////////////////////////////
// sliderButton
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-sliderButton {
  &--prev,
  &--next {
    width: clamp_size(28, 34) !important;
    height: clamp_size(28, 34) !important;
    background-color: $clr_blue;
    border-radius: 50%;

    &::after {
      content: "";
      mask-repeat: no-repeat;
      mask-size: cover;
      font-size: 0 !important;
      width: 13px;
      height: 7px;
      background-color: #fff;
      transition: background-color .3s ease-in-out;
    }

    &.swiper-button-disabled {
      background-color: $clr_gray;
    }
  }

  &--prev {
    &::after {
      mask-image: url("img/icon/icon_arrow_l.svg");
    }

    @include hover {
      &::after {
        animation: sliderArrowPrevAnimation .6s cubic-bezier(.23,1,.32,1);
    
        @keyframes sliderArrowPrevAnimation {
          0% {
            transform: translate(0);
            opacity: 1;
          }
          49.9% {
            transform: translate(-100%);
            opacity: .01;
          }
          50% {
            transform: translate(100%);
            opacity: .01;
          }
          100% {
            transform: translate(0);
            opacity: 1;
          }
        }
      }
    }
  }

  &--next {
    &::after {
      mask-image: url("img/icon/icon_arrow_r.svg");
    }

    @include hover {
      &::after {
        animation: sliderArrowNextAnimation .6s cubic-bezier(.23,1,.32,1);
    
        @keyframes sliderArrowNextAnimation {
          0% {
            transform: translate(0);
            opacity: 1;
          }
          49.9% {
            transform: translate(100%);
            opacity: .01;
          }
          50% {
            transform: translate(-100%);
            opacity: .01;
          }
          100% {
            transform: translate(0);
            opacity: 1;
          }
        }
      }
    }
  }
}