@charset "utf-8";

///////////////////////////////////////////////////////////
// 変数定義用SASS
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// ブレイクポイント
// ---------------------------------------------------------
$breakpoint_up: (
  sm: "screen and (min-width: 576px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 992px)",
  xl: "screen and (min-width: 1200px)",
  xxl: "screen and (min-width: 1400px)"
);
$breakpoint_down: (
  sm: "screen and (max-width: 575.98px)",
  md: "screen and (max-width: 767.98px)",
  lg: "screen and (max-width: 991.98px)",
  xl: "screen and (max-width: 1199.98px)",
  xxl: "screen and (max-width: 1399.98px)"
);

// ---------------------------------------------------------
// レイアウト
// ---------------------------------------------------------
// コンテナ幅
$container_width: ( fluid:100%, md:720px, lg:960px, xl:1140px, xxl:1320px );

// 余白
$gap: 3rem; // 30px

// ---------------------------------------------------------
// z-index
// ---------------------------------------------------------
$z-navBtn: 500;
$z-nav: 450;
$z-headerInner: 400;
$z-headerBg: 350;
$z-header: 300;
$z-fixedItem: 200;

// ---------------------------------------------------------
// hover
// ---------------------------------------------------------
$opacity: .6;
$transition_opacity: opacity .3s ease-in-out;

// ---------------------------------------------------------
// カラー
// ---------------------------------------------------------
// グレー系
$clr_black: #4A4A4A;
$clr_d_black: #333;
$clr_gray: #C4C4C4;
$clr_s_gray: #F9F9F9;
$clr_l_gray: #DCDCDC;
$clr_m_gray: #D9D9D9;
$clr_b_gray: #C9BBBB;
$clr_d_gray: #707070;

// オレンジ系
$clr_orange: #FFB03B;
$clr_l_orange: #FFE39B;
$clr_m_orange: #FFC36A;

// イエロー系
$clr_l_yellow: #FFFDF4;
$clr_beige: #F5EDC7;

// ブルー系
$clr_blue: #71D2DF;
$clr_l_blue: #EDF9FB;
$clr_skyblue: #C7EDF0;
$clr_navy: #4088C9;
$clr_lightblue: #C6D9EF;

// パープル系
$clr_purple: #a17ece;

// レッド系
$clr_pink: #F9C3C5;
$clr_red: #F58A8E;

// グリーン系
$clr_green: #67BA8D;
$clr_d_green: #4FBFC0;

// ---------------------------------------------------------
// デフォルトフォント設定
// ---------------------------------------------------------
// フォントサイズ
$fnt_size_default: 1.4rem;

// 太さ
$fnt_weight_default: 400;

// 行間
$fnt_lh_default: 1.6;

// フォントファミリー
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Kaisei+Opti&family=Noto+Sans:wght@100..900&display=swap');

$fnt_kaisei: "Kaisei Opti", serif;
$fnt_gothic: "Noto Sans", sans-serif;
$fnt_mincho: "Cormorant Garamond", serif;