@charset "utf-8";

///////////////////////////////////////////////////////////
// bgPhoto
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-bgPhoto {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(113, 210, 223, 0.30);
  }

  img {
    width: 100%;
    max-height: 50rem;
    min-height: 13.5rem;
    object-fit: cover;
  }
}