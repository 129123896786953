@charset "utf-8";

///////////////////////////////////////////////////////////
// lh
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;

.u-lh {
  @for $i from 10 through 30 {
    &#{$i} {
      line-height: calc(#{$i} / 10);
    }
  }
}