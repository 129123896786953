@charset "utf-8";

///////////////////////////////////////////////////////////
// header
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.header {
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  height: clamp_size(90,100,992,1440);
  width: min(96%, 136.5rem);
  background-color: #fff;
  border-radius: 1rem;
  z-index: $z-header;
  @include mq-down(lg) {
    top: 0;
    left: 0;
    transform: translateX(0);
    width: 100%;
    height: clamp_size(60,80,375,992);
    border-radius: 0;
  }

  &.is-fixed {
    position: fixed;
    animation: headerSlideDownPC 1s;
    height: 8rem;
    @include mq-down(lg) {
      height: clamp_size(60,80,375,992);
      animation: headerSlideDownSP 1s;
    }

    .header__nav_list {
      grid-template-areas:
        "nav-item01 nav-item02 nav-item03 nav-item04 nav-item05";
    }

    .header__nav_item {
      &:nth-child(7) {
        display: none;
      }
    }

    .header__nav_item--parent {
      .header__nav_link {
        padding-bottom: 28px;
      }
    }

    .header__nav_childPanel {
      top: 5.5rem;
    }
  }

  @keyframes headerSlideDownPC {
    0% {
      opacity: 0;
      transform: translate(-50%, -100px);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  @keyframes headerSlideDownSP {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.header__inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  align-items: center;
  height: inherit;
  z-index: $z-headerInner;
  @include mq-down(lg) {
    grid-template-columns: 1fr auto auto;
  }
}

.header__body {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  height: inherit;
  padding: 0 clamp_size(15,30,992,1440);
}

.header__title_link {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  transition: $transition_opacity;

  @include hover {
    opacity: $opacity;
  }
}

.header__title_logo {
  width: clamp_size(147,257,992,1440);
  @include mq-down(lg) {
    width: clamp_size(147,257,375,992);
  }
}

.header__title_name {
  display: grid;
  place-content: center;
  font-size: clamp_size(14,17,992,1440);
  font-weight: 700;
  font-family: $fnt_kaisei;
  border-left: 1px solid $clr_m_gray;
  padding-left: 1em;
  margin-left: 1em;
  height: 100%;
  @include mq-down(lg) {
    font-size: clamp_size(14,20,375,992);
  }
}

// ---------------------------------------------------------
// nav
// ---------------------------------------------------------
.header__nav {
  @include mq-down(lg) {
    position: fixed;
    top: clamp_size(60,80,375,992);
    right: 0;
    width: 100%;
    max-width: 50rem;
    height: fit-content;
    height: 80vh;
    padding: 1rem 4% 10rem;
    background-color: $clr_l_yellow;
    overflow-x: scroll;
    transition: opacity .6s, visibility .6s cubic-bezier(.47, .16, .24, 1);
    opacity: 0;
    visibility: hidden;
    z-index: $z-nav;
  }
  @include mq-down(md) {
    height: 100vh;
    height: 100dvh;
    max-width: 100%;
  }

  &.is-headerMenu-open {
    opacity: 1;
    visibility: visible;
  }
}

.header__nav_list {
  display: grid;
  gap: clamp_size(5,10,992,1440) clamp_size(12,20,992,1440);
  grid-template-columns: repeat(5, auto);
  grid-template-areas:
    "nav-item06 nav-item06 nav-item06 nav-item06 nav-item06"
    "nav-item01 nav-item02 nav-item03 nav-item04 nav-item05";
  align-items: center;
  justify-content: flex-end;
  @include mq-down(lg) {
    display: flex;
    flex-direction: column;
    grid-template-areas: none;
    gap: 0;
  }
}

.header__nav_item {
  position: relative;
  @include mq-down(lg) {
    width: 100%;
    border-bottom: 1px solid $clr_l_gray;
  }

  &:nth-child(1) {
    .header__nav_link {
      &::after {
        display: none;
      }
    }
  }

  &:nth-child(2) {
    grid-area: nav-item01;
  }

  &:nth-child(3) {
    grid-area: nav-item02;
  }

  &:nth-child(4) {
    grid-area: nav-item03;
  }

  &:nth-child(5) {
    grid-area: nav-item04;
  }

  &:nth-child(6) {
    grid-area: nav-item05;
  }

  &:nth-child(7) {
    grid-area: nav-item06;
    justify-self: flex-end;
  }

  &--parent {
    position: relative;
    padding-right: calc(17 / 17 * 1em);
    @include mq-down(lg) {
      padding-right: 0;
    }
  
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      content: "";
      display: block;
      width: calc(12 / 17 * 1em);
      height: calc(7 / 17 * 1em);
      mask-image: url("img/icon/icon_arrow_b.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      background-color: $clr_blue;
      transition: transform .3s ease-in-out;
      @include mq-down(lg) {
        display: none;
      }
    }
  
    @include hover {
      .header__nav_childList {
        opacity: 1;
        visibility: visible;
      }
    }

    .header__nav_link {
      padding-bottom: 2rem;
      @include mq-down(lg) {
        display: none;
      }
    }
  }
}

.header__nav_link,
.header__nav_parent {
  font-size: clamp_size(14,17,992,1440);
  font-weight: 700;
  font-family: $fnt_kaisei;
  transition: color .3s ease-in-out;
  @include mq-down(lg) {
    display: block;
    font-size: clamp_size(17,20,375,992);
    padding: 1.5rem 0;
  }

  @include hover {
    color: $clr_blue;
  }
}

.header__nav_link {
  @include mq-down(lg) {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
  
    &::after {
      content: "";
      display: block;
      width: 22px;
      height: 13px;
      background-image: url(img/icon/icon_arrow_r.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.header__nav_parent {
  display: none;
  @include mq-down(lg) {
    display: block;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: $clr_blue;
    }
  
    &::after {
      transform: translateY(-50%) rotate(90deg);
      transition: all .3s ease;
    }

    &.is-accordion-open {
      &::after {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
}

.header__nav_cta {
  display: none;
  @include mq-down(lg) {
    display: block;
    margin-top: 5rem;

    .p-cta {
      padding: 0;
    }

    .p-cta__list {
      max-width: 38.8rem;
    }

    .p-cta__item_link {
      padding: clamp_size(10,15,375,992) clamp_size(20,30,375,992);
    }

    .p-cta__item_obj {
      width: clamp_size(88,108,375,992);
      transform: translateY(-1.5rem);
    }
  }
}

/* メガメニュー
---------------------------------------------------------- */
.header__nav_childPanel {
  position: absolute;
  top: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 30rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s ease-in-out;
  padding-top: 2rem;
  @include mq-down(lg) {
    display: none;
    position: static;
    width: 100%;
    padding-top: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(0);
  }

  &.is-headerMegaMenu-open {
    opacity: 1;
    visibility: visible;
  }
  
  &.is-accordion-open {
    opacity: 1;
    visibility: visible;
  }
}

.header__nav_childList {
  position: relative;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.10));
  transform: translateZ(0); // safari対策
  @include mq-down(lg) {
    background-color: $clr_l_yellow;
    padding: 0 0 1.5rem 1.5rem;
    filter: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, calc(-100% + 1px));
    display: block;
    background-color: #fff;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    @include mq-down(lg) {
      display: none;
    }
  }
}

.header__nav_childItem {
  border-bottom: 1px solid $clr_l_gray;
  @include mq-down(lg) {
    border-bottom: none;
  }

  & + & {
    .header__nav_childLink {
      margin-top: 4px;
      @include mq-down(lg) {
        margin-top: 0;
      }
    }
  }
}

.header__nav_childLink {
  position: relative;
  display: block;
  font-family: $fnt_kaisei;
  font-weight: 700;
  padding: 8px 0;
  transition: color .3s ease-in-out;

  @include hover {
    color: $clr_blue;
  }

  @include mq-down(lg) {
    padding-left: 1.2em;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 1rem;
      height: 1px;
      background-color: $clr_blue;
    }
  }
}

// ---------------------------------------------------------
// cta
// ---------------------------------------------------------
.header__cta {
  width: clamp_size(93,123,992,1440);
  height: 100%;
  background-color: $clr_orange;
  background-color: hsla(36, 100%, 62%, 1);
  border-radius: 0 1rem 1rem 0;
  @include mq-down(lg) {
    width: clamp_size(70,100,375,992);
    border-radius: 0;
  }
  @include mq-down(sm) {
    display: none;
  }
}

.header__cta_link {
  display: grid;
  place-content: center;
  height: 100%;
  font-size: clamp_size(14,16,992,1440);
  font-weight: 700;
  font-family: $fnt_kaisei;
  color: #fff;
  border-radius: 0 1rem 1rem 0;
  transition: background-color .3s ease-in-out;
  @include mq-down(lg) {
    font-size: clamp_size(14,18,768,992);
  }

  @include linkColor(#fff);
  @include hover {
    background-color: hsla(36, 100%, 72%, 1);
  }
}

// ---------------------------------------------------------
// hamburger
// ---------------------------------------------------------
.header__hamburger {
  display: none;
  @include mq-down(lg) {  
    position: relative;
    display: block;
    width: clamp_size(65,100,375,992);
    height: 100%;
    padding: 1.9rem 2.2rem;
    background-color: hsla(187, 63%, 66%, 1);
    transition: background-color .3s ease-in-out;
    @include linkColor(#fff);
    @include hover {
      background-color: hsla(187, 63%, 76%, 1);
    }  
  }
  @include mq-down(md) {
    padding: clamp_size(14,18,375,768) clamp_size(14,20,375,768);
  }

  &.is-headerMenu-open {
    .header__hamburger_line {
      &:nth-child(1) {
        top: clamp_size(6,8,375,992);
        transform: rotate(20deg);
      }

      &:nth-child(2) {
        top: clamp_size(6,8,375,992);
        transform: rotate(-20deg);
      }
    }
  }
}

.header__hamburger_inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.header__hamburger_line {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all .3s ease-in-out;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 1.3rem;
    @include mq-down(md) {
      top: clamp_size(10,12,375,768);
    }
  }
}

.header__hamburger_text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-size: clamp_size(13,18,768,992);
  font-family: $fnt_kaisei;
  font-weight: 700;
  line-height: 1;
}

/* メニュー展開時 背景
---------------------------------------------------------- */
.header__bg {
  display: none;
  @include mq-down(lg) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #ffffffe5;
    opacity: 0;
    visibility: hidden;
    z-index: $z-headerBg;
    transition: opacity .6s, visibility .6s cubic-bezier(.47, .16, .24, 1);

    &.is-headerMenu-open {
      opacity: 1;
      visibility: visible;
    }
  }
}

/* メニュー展開時 背景固定
---------------------------------------------------------- */
body.is-headerMenu-open {
  height: 100%;
  overflow: hidden;
}