@charset "utf-8";

///////////////////////////////////////////////////////////
// section
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-section {
  padding: clamp_size(80, 160) 0;
}

.l-section--top {
  padding-top: clamp_size(80, 160);
}

.l-section--bottom {
  padding-bottom: clamp_size(80, 160);
}

.l-section--yellow {
  background-color: $clr_l_yellow;
}