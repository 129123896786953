@charset "utf-8";

///////////////////////////////////////////////////////////
// interviewUnit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-interviewUnit {
  position: relative;

  &--blue {
    .p-interviewUnit__link {
      background-color: $clr_blue;
    }
  }

  &--yellow {
    .p-interviewUnit__link {
      background-color: $clr_l_orange;
    }
  }

  &--purple {
    .p-interviewUnit__link {
      background-color: $clr_lightblue;
    }
  }
}

.p-interviewUnit__link {
  display: block;
  padding: 2rem;
  border-radius: 5px;

  @include hover {
    .p-interviewUnit__thumb {
      img {
        transform: scale(1.02);
      }
    }
  }
}

.p-interviewUnit__thumb {
  overflow: hidden;
  border-radius: 5px;

  img {
    width: 100%;
    aspect-ratio: 304/293;
    object-fit: cover;
    border-radius: 5px;
    transition: transform .3s ease-in-out;
  }
}

.p-interviewUnit__body {
  margin-top: 2rem;
}

.p-interviewUnit__workspace {
  font-weight: bold;
}

.p-interviewUnit__name {
  margin-top: .5rem;
}

.p-interviewUnit__joint {
  font-size: clamp_size(12, 14);
  margin-top: .5rem;
}

.p-interviewUnit__number {
  position: absolute;
  right: 0;
  bottom: 2rem;
  font-size: clamp_size(37,47,576,1440);
  font-family: $fnt_mincho;
  color: #fff;
  writing-mode: vertical-rl;
  @include mq-down(sm) {
    font-size: 4.7rem;
  }
}