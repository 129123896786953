@charset "utf-8";

///////////////////////////////////////////////////////////
// color
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;

.u-color {
  &-black {
    color: $clr_black;
  }

  &-blue {
    color: $clr_blue;
  }

  &-orange {
    color: $clr_orange;
  }
}