@charset "utf-8";

///////////////////////////////////////////////////////////
// faq
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.faq__unit {
  & + & {
    margin-top: clamp_size(70, 110);
  }

  &--skyblue {
    .faq__question {
      background-color: $clr_skyblue;
    }
  }

  &--orange {
    .faq__question {
      background-color: $clr_l_orange;
    }
  }

  &--beige {
    .faq__question {
      background-color: $clr_beige;
    }
  }

  &--purple {
    .faq__question {
      background-color: $clr_lightblue;
    }
  }
}

.faq__list {
  display: grid;
  gap: clamp_size(20, 50);
  grid-template-columns: repeat(2, 1fr);
  margin-top: clamp_size(30, 40);
  @include mq-down(md) {
    grid-template-columns: 1fr;
  }
}

.faq__content {
  display: flex;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-flow: column;
  gap: clamp_size(15, 25);
}

.faq__icon {
  display: block;
  font-size: clamp_size(17, 20);
  font-family: $fnt_kaisei;
  font-weight: 600;
  line-height: 1;
}

.faq__toggle {
  position: relative;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateY(clamp_size(10, 13));

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: .2rem;
    background-color: $clr_black;
    border-radius: 5px;
  }

  &::after {
    transform: rotate(90deg);
    transition: all .3s ease;
  }
}

/* Question
---------------------------------------------------------- */
.faq__question {
  position: relative;
  padding: clamp_size(16,20);
  padding: 1.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;

  &.is-accordion-open {
    .faq__toggle {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .faq__icon {
    line-height: 1.2;
  }
}

.faq__question_title {
  font-weight: 700;
}

/* Answer
---------------------------------------------------------- */
.faq__answer {
  display: none;
  padding: 2.5rem 0 2rem 2rem;
  transition: opacity .3s, visibility .3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  @include mq-down(sm) {
    padding: 2rem 0 2rem 2rem;
  }

  &.is-accordion-open {
    opacity: 1;
    visibility: visible;
  }

  .faq__icon {
    line-height: 1.7;
  }
}

.faq__answer_text {
  line-height: 2.2;

  & + & {
    margin-top: .6em;
  }
}