@charset "utf-8";

///////////////////////////////////////////////////////////
// outlook
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-outlook {
  position: relative;
  padding-right: 1.4em;

  &::after {
    position: absolute;
    content: "";
    width: calc(20/17*1em);
    height: calc(20/17*1em);
    right: 0;
    top: 0.1em;
    background: url(img/icon/icon_outlook.svg) center center/cover no-repeat;
  }
}