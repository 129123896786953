@charset "utf-8";

///////////////////////////////////////////////////////////
// welfare
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.welfare__buttons {
  display: flex;
  justify-content: space-between;
  @include mq-down(lg) {
    flex-wrap: wrap;
    gap: clamp_size(20,30,576,992);
    justify-content: center;
  }
  @include mq-down(sm) {
    flex-direction: column;
  }
}

.welfare__button {
  width: calc(400/1300*100%);
  @include mq-down(lg) {
    width: clamp_size(250,360,576,992);
  }
  @include mq-down(sm) {
    width: 100%;
  }
}

.welfare__button_link {
  position: relative;
  display: block;
  padding: .72em 2.2em;
  border: 1px solid $clr_blue;
  border-radius: 5px;
  font-size: clamp_size(18, 26);
  font-weight: 700;
  font-family: $fnt_kaisei;
  text-align: center;
  transition: color .3s, background-color .3s ease-in-out;

  .c-circleArrow {
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
    transition: background-color .3s ease-in-out;
    @include mq-down(md) {
      
    }

    &::after {
      transition: background-color .3s ease-in-out;
    }
  }

  @include hover {
    color: #fff;
    background-color: $clr_blue;

    .c-circleArrow {
      background-color: #fff;

      &::after {
        background-color: $clr_blue;
      }
    }
  }
}

.welfare__unit {
  margin-top: clamp_size(70, 100);
}

.welfare__list {
  display: grid;
  gap: clamp_size(30, 50) clamp_size(20, 50);
  grid-template-columns: repeat(2, 1fr);
  margin-top: clamp_size(20, 40);
  @include mq-down(md) {
    grid-template-columns: 1fr;
  }
}

.welfare__item {

}

.welfare__item_title {
  font-size: clamp_size(14, 17);
  font-weight: 700;
}

.welfare__text {
  line-height: 2;
  margin-top: calc(10/17*1em);
}