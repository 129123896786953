@charset "utf-8";

///////////////////////////////////////////////////////////
// index
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// fv
// ---------------------------------------------------------
.fv {
  overflow: hidden;
}

.fv__inner {
  position: relative;
  display: grid;
  gap: 6rem;
  grid-template-columns: repeat(2, 1fr);
  @include mq-down(md) {
    gap: clamp_size(30,60,375,768);
  }
}

.fv__copy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + clamp_size(30,60,768,1440)), calc(-50% + clamp_size(40,100,1440,768)));
  width: 100%;
  text-align: center;
  @include mq-down(md) {
    transform: translate(calc(-50% + clamp_size(20,30,375,768)), calc(-50% + 10.5rem));
  }
}

.fv__copy_main {
  font-size: calc(50/1440*100vw);
  font-family: $fnt_kaisei;
  font-weight: 700;
  line-height: 1.32;
  @media screen and (max-width: 1440px) {
    font-size: clamp_size(40,50,768,1440);
  }
  @include mq-down(md) {
    font-size: clamp_size(25,40,375,768);
  }
}

.fv__copy_sub {
  font-size: clamp_size(10,14);
  font-family: $fnt_kaisei;
  font-weight: 700;
  margin-top: clamp_size(5,10);
  margin-left: -1.5em;
}

.fv__obj {
  &--left,
  &--right {
    @include mq-down(lg) {
      margin-top: 4rem;
    }
    @include mq-down(md) {
      margin-top: clamp_size(40,60,768,375);
    }
  }

  &--bottom {
    margin-top: -12rem;
    margin-top: clamp_size(-120,-80,1440,992);
    @include mq-down(lg) {
      margin-top: -3rem;
    }
    @include mq-down(md) {
      margin-top: clamp_size(-30,30,768,375);
    }
  }

  img {
    width: 100%;
  }
}

/// ---------------------------------------------------------
// top__pickupNews
// ---------------------------------------------------------
.top__pickupNews {
  padding: 3rem 0;
  background-color: $clr_l_yellow;
}

.top__pickupNews_item {
  width: 60vw;
  background-color: #fff;
  border-radius: 0 5px 5px 0;
  padding: clamp_size(15, 30) 3rem clamp_size(15, 30) calc(66/580*100%);
  @media screen and (max-width: 1440px) {
    width: 50vw;
  }
  @include mq-down(lg) {
    width: calc(580/1440*100%);
    min-width: 58rem;
  }
  @include mq-down(md) {
    min-width: 50rem;
  }
  @include mq-down(sm) {
    width: min(96%,72.2rem);
    min-width: auto;
    padding-left: calc(20/722*100%);
  }
}

.top__pickupNews_link {
  display: grid;
  gap: 1.5rem;
  align-items: center;
  grid-template-columns: auto 1fr auto;

  @include hover {
    .top__pickupNews_title {
      color: $clr_blue;
    }

    .top__pickupNews_icon {
      &::after {
        animation: circleArrowAnimation .6s cubic-bezier(.23,1,.32,1);
    
        @keyframes circleArrowAnimation {
          0% {
            transform: translate(0);
            opacity: 1;
          }
          49.9% {
            transform: translate(100%);
            opacity: .01;
          }
          50% {
            transform: translate(-100%);
            opacity: .01;
          }
          100% {
            transform: translate(0);
            opacity: 1;
          }
        }
      }
    }
  }
}

.top__pickupNews_title {
  transition: color .3s ease-in-out;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.top__pickupNews_icon {
  display: grid;
  place-content: center;
  width: 2.8rem;
  height: 2.8rem;
  background-color: $clr_blue;
  border-radius: 50%;

  &::after {
    content: "";
    display: block;
    width: 11px;
    height: 6px;
    mask-image: url("img/icon/icon_arrow_r.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: #fff;
    z-index: 1;
  }
}

// ---------------------------------------------------------
// message
// ---------------------------------------------------------
.top__message {
  text-align: center;
  padding-top: 7rem;
  background-color: $clr_l_yellow;
  overflow: hidden;
  @include mq-down(md) {
    padding-top: clamp_size(50,70,375,768);
  }
}

.top__message_inner {
  position: relative;
  @include mq-down(md) {
    padding-top: clamp_size(30,50,375,768);
  }
}

.top__message_copy {
  font-size: clamp_size(16, 28);
  font-family: $fnt_kaisei;
  font-weight: 700;
  line-height: 1.8;
  margin-top: calc(60/28*1em);
}

.top__message_text {
  line-height: 2.2;
  margin-top: calc(30/17*1em);
}

.top__message_obj {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include mq-down(md) {
    top: 0;
    transform: translateY(0);
  }

  &--01 {
    left: -6.5%;
    width: clamp_size(208,348,768,1440);
    @include mq-down(lg) {
      left: -10.5%;
    }
    @include mq-down(md) {
      width: clamp_size(94,208,375,768);
    }
  }

  &--02 {
    right: -19.2%;
    width: clamp_size(308,558,768,1440);
    @include mq-down(lg) {
      right: -24.2%;
    }
    @include mq-down(md) {
      width: clamp_size(150,308,375,768);
    }
  }
}

// ---------------------------------------------------------
// about
// ---------------------------------------------------------
.top__about {
  position: relative;
  padding: 7rem 0 clamp_size(80, 160);
  background-image: image-set(url(img/top/img_about_bg.png) 1x, url(img/top/img_about_bg@2x.png) 2x);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  @include mq-down(md) {
    padding: clamp_size(80, 160) 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(113, 210, 223, 0.80);
  }
}

.top__about_inner {
  position: relative;
  z-index: 1;
}

.top__about_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  @include mq-down(md) {
    flex-direction: column;
  }
}

.top__about_head_content {
  width: calc(515/1300*100%);
  @include mq-down(md) {
    width: 100%;
  }
}

.top__about_head_text {
  line-height: 2.2;
  margin-top: calc(15/17*1em);
}

.top__about_head_link {
  margin-top: clamp_size(20,50,768,1440);
  text-align: right;
  @include mq-down(md) {
    text-align: left;
  }
}

.top__about_head_linkWrap {
  display: inline-block;
}

.top__about_head_image {
  width: calc(695/1300*100%);
  @media screen and (max-width: 1440px) {
    margin-right: calc(50% - 50vw);
  }
  @include mq-down(md) {
    width: 100vw;
    margin-top: 5rem;
  }

  img {
    width: 100%;
    border-radius: 5px;
    @media screen and (max-width: 1440px) {
      border-radius: 5px 0 0 5px;
    }
  }
}

.top__about_map {
  margin-top: clamp_size(70,100,768,1440);

  @include mq-down(md) {
    display: none;
  }
}

// ---------------------------------------------------------
// workplace
// ---------------------------------------------------------
.top__workplace_menuList {
  display: grid;
  align-items: center;
  gap: clamp_size(20,50,992,1440);
  grid-template-columns: repeat(3, 1fr);
  margin-top: clamp_size(40, 60);
}

.top__workplace_menuLink {
  transition: $transition_opacity;

  @include hover {
    opacity: $opacity;
  }
}

.top__workplace_units {
  margin-top: clamp_size(60, 100);
}

.top__workplace_bg {
  position: relative;
  margin-top: clamp_size(80, 160);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(71, 212, 221, 0.35);
  }

  img {
    width: 100%;
  }
}

// ---------------------------------------------------------
// interview
// ---------------------------------------------------------
.top__interview_inner {
  display: grid;
  gap: clamp_size(40,100,768,1440);
  grid-template-columns: auto 1fr;
  padding: clamp_size(30, 100) 0 clamp_size(30, 100) clamp_size(30, 80);
  background-color: $clr_l_yellow;
  margin-right: calc(50% - 50vw);
  border-radius: 5px 0 0 5px;
  @media screen and (min-width: 1600px) {
    margin-right: 0;
    padding: clamp_size(30, 100) clamp_size(30, 80);
  }
  @include mq-down(md) {
    grid-template-columns: 1fr;
  }
}

.top__interview_head {
  position: relative;
  @include mq-down(md) {
    display: grid;
    grid-template-columns: auto auto;
    gap: clamp_size(20,30,375,768);
    overflow-x: hidden;
  }
}

.top__interview_sliderControl {
  display: flex;
  gap: 3rem;
  align-items: center;
  position: relative;
  margin-top: 2rem;

  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin-top: 0;
  }

  .swiper-pagination {
    position: static;
    width: auto;
    font-size: clamp_size(17, 22);
    font-family: $fnt_mincho;
    font-weight: 600;
  }

  .swiper-pagination-current {
    margin-right: .5em;
  }

  .swiper-pagination-total {
    margin-left: .5em;
  }

  &.is-stop {
    display: none;
  }
}

.top__interview_obj {
  position: absolute;
  left: 0;
  bottom: -9.5rem;
  @include mq-down(md) {
    position: static;
    width: clamp_size(130,180,375,768);
    transform: translateX(clamp_size(40,0,375,768));
  }
}

.top__interview_slider {
  overflow: hidden;

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    width: 34.4rem;
    @include mq-down(xl) {
      width: 30.4rem;
    }
    @include mq-down(lg) {
      width: 26.4rem;
    }
    @include mq-down(sm) {
      width: 20rem;
    }
  }

  .p-interviewUnit__link {
    @include mq-down(sm) {
      padding: 1.5rem;
    }
  }

  .p-interviewUnit__number {
    @include mq-down(sm) {
      font-size: clamp_size(37,47,375,576);
    }
  }

  &.is-stop {
    .swiper-wrapper {
      justify-content: center;
    }
  }
}

.top__interview_sliderWrap {
  width: min(92%, 130rem);
}

// ---------------------------------------------------------
// data
// ---------------------------------------------------------
.top__data_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mq-down(md) {
    flex-direction: column-reverse;
  }
}

.top__data_thumb {
  width: calc(695/1300*100%);
  @media screen and (max-width: 1440px) {
    margin-left: calc(50% - 50vw);
  }
  @include mq-down(md) {
    width: auto;
    margin-top: 5rem;
  }

  img {
    width: 100%;
    border-radius: 5px;
    @media screen and (max-width: 1440px) {
      border-radius: 0 5px 5px 0;
    }
  }
}

.top__data_content {
  max-width: 56rem;
  @media screen and (max-width: 1440px) {
    width: calc(608/1300*100%);
    max-width: 100%;
  }
  @include mq-down(md) {
    width: 100%;
  }
}

.top__data_text {
  line-height: 2.2;
  margin-top: calc(15/17*1em);
}

.top__data_link {
  margin-top: clamp_size(20,50,768,1440);
}

.top__data_linkWrap {
  display: inline-block;
}

// ---------------------------------------------------------
// news
// ---------------------------------------------------------

.top__news_inner {
  display: grid;
  gap: clamp_size(50,120,768,1440);
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  @include mq-down(md) {
    grid-template-columns: 1fr;
  }
}

.top__news_head {
  @include mq-down(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.top__news_link {
  margin-top: clamp_size(30,50,768,1440);
}

.top__news_linkWrap {
  display: inline-block;
}

.top__news_list {

}

.top__news_item {
  border-bottom: 1px solid $clr_l_gray;

  &:nth-child(1) {
    .top__news_item_link {
      padding-top: 0;
    }
  }
}

.top__news_item_link {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  padding: clamp_size(15, 20) 0;

  @include hover {
    .top__news_item_title {
      color: $clr_blue;
    }

    .c-circleArrow {
      &::after {
        animation: circleArrowAnimation .6s cubic-bezier(.23,1,.32,1);
    
        @keyframes circleArrowAnimation {
          0% {
            transform: translate(0);
            opacity: 1;
          }
          49.9% {
            transform: translate(100%);
            opacity: .01;
          }
          50% {
            transform: translate(-100%);
            opacity: .01;
          }
          100% {
            transform: translate(0);
            opacity: 1;
          }
        }
      }
    }
  }
}

.top__news_item_title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  transition: color .3s ease-in-out;
  margin-top: calc(6/17*1em);
}

// ---------------------------------------------------------
// contact
// ---------------------------------------------------------
.top__contact {
  padding-bottom: clamp_size(130, 200);
}

.top__contact_inner {
  padding: clamp_size(30, 50);
  border-radius: 5px;
  border: 1px solid $clr_blue;
  background-color: #fff;
}

.top__contact_wrap {
  display: grid;
  align-items: center;
  gap: clamp_size(35,120,992,1440);
  grid-template-columns: auto auto;
  justify-content: space-between;
  width: fit-content;
  margin: 0 auto;
  @include mq-down(lg) {
    gap: 6rem;
  }
  @include mq-down(md) {
    gap: 2rem;
    grid-template-columns: 1fr;
  }
}

.top__contact_head {
  @include mq-down(md) {
    .c-sectionTitle {
      text-align: center;
    }
  }
}

.top__contact_info {
  display: grid;
  gap: clamp_size(15,50,992,1440);
  grid-template-columns: auto auto;
  @include mq-down(lg) {
    grid-template-columns: 1fr;
  }
}

.top__contact_tel {
  text-align: center;

  .c-tel {
    font-size: clamp_size(26, 32);
    font-weight: 500;
    color: $clr_blue;
  }
}

.top__contact_time {
  font-size: clamp_size(12, 14);
}

.top__contact_address {
  font-size: clamp_size(14,17,992,1440);
  line-height: 2;
  @include mq-down(lg) {
    font-size: clamp_size(14,15,375,992);
    text-align: center;
  }
}