@charset "utf-8";

///////////////////////////////////////////////////////////
// contents
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-contents {
  padding: clamp_size(80, 160) 0 clamp_size(70,100,992,1440);
  @include mq-down(lg) {
    padding-bottom: clamp_size(80, 160);
  }

  &--noBottom {
    padding-bottom: 0;
  }
}

.l-contents--top {
  padding-top: clamp_size(80, 160);
}