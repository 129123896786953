@charset "utf-8";

///////////////////////////////////////////////////////////
// workplace
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// gallery
// ---------------------------------------------------------
:root {
  --count: 0;
}

.workplace__gallery {
  padding: clamp_size(70, 100) 0;
  background-color: $clr_l_yellow;
  overflow: hidden;

  .c-heading {
    text-align: center;
  }
}

.workplace__gallery_box {
  margin-top: 4rem;
}

.workplace__gallery_slider {
  overflow: hidden;

  .swiper {
    overflow: visible;
  }

  &.is-stop {
    width: min(92%, 130rem);
    margin: 0 auto;

    .workplace__gallery_slide {
      max-width: 65rem;
      margin: 0 auto;

      img {
        transform: scale(1);
      }
    }

    .workplace__gallery_sliderControl {
      display: none;
    }
  }

  .swiper-slide {
    width: 65rem;

    img {
      width: 100%;
      aspect-ratio: 650/417;
      object-fit: cover;
      transform: scale(.8);
      transition: transform .6s cubic-bezier(.47, .16, .24, 1);
    }
  }

  .swiper-slide[class*=-active] {
    img {
      transform: scale(1);
    }
  }
}

.workplace__gallery_sliderControl {
  display: grid;
  grid-template-columns: 1fr 16.2rem;
  gap: 3rem;
  align-items: center;
  position: relative;
  margin-top: 3rem;
  @include mq-down(md) {
    grid-template-columns: 1fr 11.2rem;
  }
  @include mq-down(sm) {
    gap: 1.5rem;
    grid-template-columns: 1fr;
  }

  /* プログレスバー */
  .swiper-horizontal > .swiper-pagination-progressbar,
  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    bottom: 0;
    top: auto;
    height: 2px;
  }

  /* プログレスバーの背景色変更 */
  .swiper-pagination-progressbar {
    background-color: #D1D1D1;
  }
  
  /* プログレスバーの色変更 */
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: $clr_black;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin-top: 0;
  }

  .swiper-pagination {
    position: static;
    width: auto;
  }
}

.workplace__gallery_slideButtons {
  display: flex;
  justify-content: space-between;
  @include mq-down(sm) {
    justify-content: center;
    gap: 2rem;
  }

  .p-sliderButton {
    &--prev,
    &--next {
      width: 6.7rem !important;
      height: 6.7rem !important;
      background-color: #fff;
      border: 1px solid $clr_d_black;
      @include mq-down(md) {
        width: 4.7rem !important;
        height: 4.7rem !important;
      }
      @include mq-down(sm) {
        width: 3.7rem !important;
        height: 3.7rem !important;
      }
  
      &::after {
        background-color: $clr_d_black;
        width: 17px;
        height: 10px;
        transition: background-color .4s ease-in-out;
      }
  
      @include hover {
        background-color: $clr_d_black;
  
        &::after {
          background-color: #fff;
        }
      }
    }
  }
}


// ---------------------------------------------------------
// info
// ---------------------------------------------------------
.workplace__info {
  padding-top: clamp_size(80, 160);

  .c-heading {
    text-align: center;
  }
}

.workplace__info_access {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp_size(20,50,768,1440);
  margin-top: 4rem;
  @include mq-down(lg) {
    grid-template-columns: 1fr;
  }
}

.workplace__info_data {
  border-top: 1px solid $clr_l_gray;
}

.workplace__info_data_wrap {
  display: grid;
  gap: clamp_size(50,130,768,1440);
  grid-template-columns: 5em 1fr;
  align-items: center;
  padding: 2rem 0;
  border-bottom: 1px solid $clr_l_gray;

  dt,dd {
    line-height: 2;
  }
}

.workplace__info_map {
  iframe {
    width: 100%;
    aspect-ratio: 628/404;
  }
}

.workplace__info_banner {
  margin-top: 6rem;
}

.workplace__info_units {
  margin-top: clamp_size(80, 160);
}