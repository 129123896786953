@charset "utf-8";

///////////////////////////////////////////////////////////
// circleArrow
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-circleArrow {
  display: grid;
  place-content: center;
  width: clamp_size(26, 34);
  height: clamp_size(26, 34);
  border-radius: 50%;
  background-color: $clr_blue;

  &::after {
    content: "";
    display: block;
    width: 17px;
    height: 9px;
    mask-image: url("img/icon/icon_arrow_b_r.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: #fff;
    z-index: 1;
    @include mq-down(md) {
      width: 12px;
      height: 7px;
    }
  }
  
  &--bottom {
    &::after {
      width: 9px;
      height: 17px;
      mask-image: url("img/icon/icon_arrow_b_b.svg");
      @include mq-down(md) {
        width: 7px;
        height: 13px;
      }
    }
  }

  &--diagonal {
    &::after {
      width: 14px;
      height: 10px;
      mask-image: url("img/icon/icon_arrow_b_d.svg");
      @include mq-down(md) {
        width: 10px;
        height: 7px;
      }
    }
  }
}