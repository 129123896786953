@charset "utf-8";

///////////////////////////////////////////////////////////
// workplaceUnit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-workplaceUnits {
  @media screen and (min-width: 1600px) {
    overflow: hidden;
  }
}

.p-workplaceUnit {
  & + & {
    margin-top: clamp_size(60, 100);
  }
}

.p-workplaceUnit__head {
  position: relative;
}

.p-workplaceUnit__head_button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(calc(-50% - 2px));
  display: flex;
  gap: 1.5rem;
  align-items: center;

  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin-top: 0;
  }
}

.p-workplaceUnit__slider {
  width: 100vw;
  margin: clamp_size(20, 30) calc(50% - 50vw);
  overflow: hidden;

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    width: 40rem;
    margin-right: 50px;
    
    @include mq-down(lg) {
      width: 30rem;
    }
    @include mq-down(md) {
      width: 25rem;
    }
  }
}

.p-workplaceUnit__sliderWrap {
  width: min(92%, 130rem);
  margin: 0 auto;
}

.p-workplaceUnit__slide_link {
  display: block;

  @include hover {
    .p-workplaceUnit__slide_thumb {
      img {
        transform: scale(1.02);
      }
    }
  }
}

.p-workplaceUnit__slide_thumb {
  overflow: hidden;
  border-radius: 5px;

  img {
    border-radius: 5px;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 402/239;
    transition: transform .3s ease-in-out;
  }
}

.p-workplaceUnit__slide_text {
  margin-top: calc(10/17*1em);
  text-align: center;
}