@charset "utf-8";

///////////////////////////////////////////////////////////
// bg
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-bg {
  background-size: 33.3%;
  background-repeat: repeat;
  background-image: image-set(url(img/common/img_bg.png) 1x, url(img/common/img_bg@2x.png) 2x);
}