@charset "utf-8";

///////////////////////////////////////////////////////////
// entry
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

#confirmScreen,
#completeScreen {
  display: none;
}

.entry__form {
  margin-top: clamp_size(90, 160);

  .c-heading {
    text-align: center;
  }

  &--confirm,
  &--complete {
    .entry__form_body {
      margin-top: 4rem;
    }
  }
}

.entry__form_steps {
  position: relative;
  display: flex;
  gap: clamp_size(30, 100);
  justify-content: center;
  width: fit-content;
  margin: clamp_size(20, 40) auto 0;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $clr_l_gray;
    z-index: -1;
  }
}

.entry__form_step {
  position: relative;
  display: grid;
  place-content: center;
  width: clamp_size(88, 138);
  height: 12.4rem;

  &.is-current {
    .entry__form_step_text {
      color: #fff;
    }
  }
}

.entry__form_step_text {
  font-size: clamp_size(16, 22);
  font-weight: 700;
  font-family: $fnt_kaisei;
  text-align: center;
}

.entry__form_step_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
}

.entry__form_cautions {
  margin-top: clamp_size(30, 40);

  .c-caution {
    .strong {
      color: $clr_pink;
    }
  }
}

.entry__form_body {
  margin-top: 2rem;
}

// ---------------------------------------------------------
// entry__form_complete
// ---------------------------------------------------------
.entry__form_complete {
  text-align: center;
}

.entry__form_complete_title {
  margin-top: calc(40/32*1em);
  font-weight: 400;
}

.entry__form_complete_sentence {
  font-size: clamp_size(13, 15);
  text-align: center;
  margin-top: calc(32/15*1em);

  p {
    line-height: 1.8;

    &:not(:first-child) {
      margin-top: 1.4em;
    }
  }
}