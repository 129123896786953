@charset "utf-8";

///////////////////////////////////////////////////////////
// banner
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;


.p-banner__link {
  position: relative;
  display: grid;
  place-content: center;
  height: 15rem;
  border-radius: 5px;
  border: 1px solid $clr_blue;
  text-align: center;
  background-color: #fff;
  transition: background-color .3s ease-in-out;
  @include mq-down(sm) {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    justify-content: center;
    height: 13rem;
  }

  @include hover {
    background-color: hsla(187, 63%, 96%, 1);
  }
}

.p-banner__title {
  font-size: clamp_size(18, 26);
  font-weight: 700;
  font-family: $fnt_kaisei;
}

.p-banner__subTitle {
  margin-top: calc(5/17*1em);
}

.p-banner__logo {
  margin-top: 5px;
}

.p-banner__arrow {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  @include mq-down(sm) {
    position: static;
    transform: translate(0);
  }
}

.p-banner__obj {
  position: absolute;
  top: 0;
  left: clamp_size(20,111,768,1440);
  transform: translateY(-1.5rem);
  width: clamp_size(156,221,768,1440);
  @include mq-down(md) {
    display: none;
  }
}