@charset "utf-8";

///////////////////////////////////////////////////////////
// pager
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $fnt_kaisei;
  margin-top: clamp_size(50, 70);

  a,
  span {
    padding: 0 !important;
  }

  .current,
  .previouspostslink,
  .nextpostslink,
  .extend {
    margin: 0 clamp_size(5, 10);
  }
  
  .current,
  .previouspostslink,
  .nextpostslink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp_size(38, 48);
    height: clamp_size(38, 48);
    border-radius: 50%;
    background-color: $clr_blue;
  }

  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp_size(38, 48);
    height: clamp_size(38, 48);
    transition: color .3s ease-in-out;

    @include hover {
      color: $clr_blue;
    }
  }

  span.current {
    color: #fff;
    pointer-events: none;
    box-sizing: content-box;
  }

  .previouspostslink,
  .nextpostslink {
    transition: background-color .3s ease-in-out;

    &::after {
      content: "";
      display: block;
      mask-image: url("img/icon/icon_arrow_t_b.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      background-color: #fff;
      width: 10px;
      height: 5px;
      transform: translateX(1px) rotate(-90deg);
    }

    @include hover {
      background-color: hsla(187, 63%, 74%, 1);
    }
  }

  .previouspostslink {    
    &::after {
      transform: translateX(-1px) rotate(90deg);
    }
  }

  .first,
  .last {
    display: none;
  }

  .extend {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr_blue;
    width: 1rem;
    height: 1rem;
  }
}