@charset "utf-8";

///////////////////////////////////////////////////////////
// sectionTitle
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-sectionTitle {
  text-align: center;
  
  .ja {
    font-size: clamp_size(16, 18);
    font-family: $fnt_kaisei;
    font-weight: 500;
  }

  .en {
    display: block;
    font-size: clamp_size(55, 100);
    font-family: $fnt_mincho;
    font-weight: 600;
    color: $clr_blue;
    text-transform: capitalize;
    line-height: 1.2;
  }

  &--left {
    text-align: left;
  }
}