@charset "utf-8";

///////////////////////////////////////////////////////////
// suvFv
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-subFv {
  position: relative;
  height: clamp_size(226, 446);
  padding-top: 13rem;
  @include mq-down(lg) {
    padding-top: clamp_size(60,80,375,992);
  }
  @include mq-down(md) {
    display: grid;
    align-items: center;
    background-color: $clr_l_yellow;
  }
}

.p-subFv__inner {
  position: relative;
  padding-top: clamp_size(15, 80);
  @include mq-down(md) {
    padding-top: 0;
  }
}

.p-subFv__wrap {
  position: relative;
}

.p-subFv__heading {
  @include mq-down(md) {
    text-align: center;
  }

  .en {
    font-size: clamp_size(16, 22);
    font-family: $fnt_mincho;
    font-weight: 700;
    color: $clr_blue;
    text-transform: capitalize;
    @include mq-down(md) {
      display: none;
    }
  }

  .ja {
    font-size: clamp_size(22, 50);
    font-family: $fnt_kaisei;
    font-weight: 700;
    line-height: normal;
  }
}

.p-subFv__obj {
  position: absolute;
  top: 2rem;
  right: clamp_size(0,50,768,1440);
  @include mq-down(md) {
    display: none;
  }
}

.p-subFv__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  @include mq-down(md) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#about {
  .p-subFv__obj {
    top: -5rem;
    width: clamp_size(327,447,768,1440);
    @include mq-down(lg) {
      top: 0;
    }
  }
}

#data {
  .p-subFv__obj {
    top: -5rem;
    width: clamp_size(279,379,1200,1440);
    @include mq-down(lg) {
      top: 0;
    }
  }
}

#workplace {
  .p-subFv__obj {
    top: -5rem;
    width: clamp_size(279,379,768,1440);
    @include mq-down(lg) {
      top: 0;
    }
  }
}

#welfare {
  .p-subFv__obj {
    top: -1rem;
    right: 10rem;
    width: clamp_size(118,148,768,1440);
    @include mq-down(lg) {
      top: 0;
      right: 8rem;
    }
  }
}

#faq {
  .p-subFv__obj {
    top: -2rem;
    right: 8rem;
    width: clamp_size(226,276,768,1440);
    @include mq-down(lg) {
      top: 0;
      right: 2rem;
    }
  }
}

#recruitment {
  .p-subFv__obj {
    top: -3rem;
    right: 10rem;
    width: clamp_size(195,215,768,1440);
    @include mq-down(lg) {
      top: 0;
      right: 5rem;
    }
  }
}

#news {
  .p-subFv__obj {
    top: 3rem;
    right: clamp_size(20,100,768,1440);
  }
}

#entry {
  .p-subFv__obj {
    top: -2rem;
    width: clamp_size(213,266,768,1440);
    @include mq-down(lg) {
      top: 0;
    }
  }
}

#error {
  .p-subFv__obj {
    top: -4rem;
    @include mq-down(lg) {
      top: -2rem;
    }
  }
}