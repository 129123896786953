@charset "utf-8";

///////////////////////////////////////////////////////////
// heading
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-heading {
  font-weight: 700;

  &--middle {
    font-size: clamp_size(18, 22);
  }

  &--large {
    font-size: clamp_size(22, 26);
  }

  &--xlarge {
    font-size: clamp_size(26, 32);
  }

  &--kaisei {
    font-family: $fnt_kaisei;
  }

  &--blue {
    &.c-heading--border {
      border-color: $clr_blue;

      &::before {
        background-color: $clr_blue;
      }

      .num {
        color: $clr_blue;
      }
    }
  }

  &--skyblue {
    &.c-heading--en {
      &::after {
        color: $clr_skyblue;
      }
    }

    &.c-heading--border {
      border-color: $clr_skyblue;

      &::before {
        background-color: $clr_skyblue;
      }
    }
  }

  &--orange {
    &.c-heading--en {
      &::after {
        color: $clr_l_orange;
      }
    }

    &.c-heading--border {
      border-color: $clr_l_orange;

      &::before {
        background-color: $clr_l_orange;
      }
    }
  }

  &--beige {
    &.c-heading--en {
      &::after {
        color: $clr_beige;
      }
    }

    &.c-heading--border {
      border-color: $clr_beige;

      &::before {
        background-color: $clr_beige;
      }
    }
  }

  &--purple {
    &.c-heading--en {
      &::after {
        color: $clr_lightblue;
      }
    }

    &.c-heading--border {
      border-color: $clr_lightblue;

      &::before {
        background-color: $clr_lightblue;
      }
    }
  }

  &--en {
    display: flex;
    align-items: center;
    gap: 1rem;

    &::after {
      content: attr(data-en);
      font-size: clamp_size(13, 18);
    }
  }

  &--border {
    position: relative;
    display: flex;
    align-items: center;
    font-size: clamp_size(20, 26);
    font-family: $fnt_kaisei;
    padding-left: 3.5rem;
    padding-bottom: .2em;
    border-bottom: 1px solid;
    @include mq-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      padding-left: 3rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 100%;
    }

    .num {
      font-size: calc(46/26*1em);
      line-height: 1.2;
      margin-right: calc(12/26*1em);
    }
  }
}