@charset "utf-8";

///////////////////////////////////////////////////////////
// interview
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// 一覧
// ---------------------------------------------------------
.interview__list {
  display: grid;
  gap: 5rem clamp_size(20,50,576,1440);
  grid-template-columns: repeat(3, 1fr);
  @include mq-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(sm) {
    grid-template-columns: 1fr;
  }
}

// ---------------------------------------------------------
// 詳細
// ---------------------------------------------------------
.interview__head_profile {
  display: flex;
  align-items: center;
  @include mq-down(sm) {
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
  }
}

.interview__head_profile_name {
  font-size: clamp_size(22, 26);
}

.interview__head_profile_info {
  font-size: clamp_size(13, 14);
  padding-left: 1em;
  margin-left: 1em;
  border-left: 1px solid $clr_blue;
  @include mq-down(sm) {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }
}

.interview__head_inner {
  display: grid;
  grid-template-columns: calc(548/1300*100%) calc(708/1300*100%);
  justify-content: space-between;
  margin-top: 2rem;
  @include mq-down(lg) {
    gap: 3rem;
    grid-template-columns: 1fr;
  }
}

.interview__head_thumb {
  @include mq-down(lg) {
    max-width: 54.8rem;
    margin: 0 auto;
  }
  
  img {
    aspect-ratio: 548/527;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.interview__head_schedule {
  padding: clamp_size(20, 40);
  border-radius: 5px;
  background-color: $clr_l_yellow;
}

.interview__head_schedule_steps {
  position: relative;
  margin-top: clamp_size(20, 40);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(21/2*1px);
    transform: translateY(-50%);
    display: block;
    width: 1px;
    height: 90%;
    background-color: $clr_l_gray;
  }
}

.interview__head_schedule_step {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;

  & + & {
    margin-top: 3rem;
  }
  
  &::before {
    content: "";
    display: block;
    width: 2.1rem;
    height: 2.1rem;
    background-color: $clr_m_orange;
    border-radius: 50%;
  }

  dt {
    font-size: clamp_size(18, 22);
    font-weight: 700;
    font-family: $fnt_kaisei;
    margin-left: 1rem;
  }

  dd {
    margin-left: 2rem;
  }
}

/* interview__content
---------------------------------------------------------- */
.interview__content {
  margin-top: clamp_size(70, 100);

  figure {
    margin: clamp_size(50, 100) 0;

    img {
      border-radius: 5px;
    }
  }
}

.interview__content_unit {
  display: grid;
  gap: 2rem;
  grid-template-columns: clamp_size(270,330,768,1440) 1fr;
  @include mq-down(md) {
    grid-template-columns: 1fr;
  }
  @include mq-down(sm) {
    gap: 1.5rem;
  }

  & + & {
    margin-top: clamp_size(40, 60);
  }
}

.interview__content_unit_title {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: clamp_size(10, 20);
  font-size: clamp_size(24,32,768,1440);
  font-weight: 700;
  font-family: $fnt_kaisei;
  @include mq-down(md) {
    font-size: clamp_size(22,28,375,768);
  }

  &::before {
    content: "";
    width: 4.5rem;
    width: clamp_size(35, 45);
    height: 1px;
    background-color: $clr_blue;
    transform: translateY(.85em);
  }
}

.interview__content_unit_sentence {
  p {
    line-height: 2.2;

    &:not(:first-child) {
      margin-top: 1.6em;
    }
  }
}

/* interview__button
---------------------------------------------------------- */
.interview__button {
  margin-top: 6rem;

  .p-circleLink_text {
    font-family: $fnt_gothic;
  }
}