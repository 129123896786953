@charset "utf-8";

///////////////////////////////////////////////////////////
// select
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-select {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 2.5rem;
    transform: translateY(-50%);
    display: block;
    width: 10px;
    height: 5px;
    mask-image: url("img/icon/icon_arrow_t_b.svg"); // 矢印アイコン
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: $clr_blue;
  }

  select {
    width: 100%;
    height: 5rem;
    padding: 0 2rem;
    font-size: clamp_size(13,16);
    border-radius: 5px;
    border: 1px solid $clr_l_gray;
    color: $clr_black;
  }
}
