@charset "utf-8";

///////////////////////////////////////////////////////////
// entrance
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-entrance {
  text-align: center;
}

.p-entrance__text {
  line-height: 2;
  margin-top: calc(32/17*1em);
}