@use "../foundation/mixins" as *;

.u-icon {
  &-right {
    &-angle-right {
      @include icon("\f105", "right");
    }

    &-truck {
      @include icon("\f0d1", "right");
    }
  }

  &-envelope {
    @include icon("\f0e0");
  }

  &-facebook {
    @include icon("\f39e", "left", "brands");
  }

  &-twitter {
    @include icon("\f099", "left", "brands");
  }
}
