@charset "utf-8";

///////////////////////////////////////////////////////////
// text
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

$alignList: Left,
Center,
Right,
Justify;

@each $align in $alignList {
  .u-text#{$align} {
    text-align: #{$align} !important;
  }
}

@include mq-down(md) {
  @each $align in $alignList {
    .u-textMd#{$align} {
      text-align: #{$align} !important;
    }
  }
}

@include mq-down(sm) {
  @each $align in $alignList {
    .u-textSm#{$align} {
      text-align: #{$align} !important;
    }
  }
}