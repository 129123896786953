@charset "utf-8";

///////////////////////////////////////////////////////////
// data
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.data {
  .c-heading--border {
    font-size: clamp_size(20,26,768,1440);
    @include mq-down(sm) {
      flex-direction: row;
      align-items: center;
      font-size: clamp_size(18,20,375,768);
    }
  }
}

.data__units {
  display: grid;
  gap: 10rem 5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "data-01 data-01"
    "data-02 data-03"
    "data-04 data-05"
    "data-06 data-06"
    "data-07 data-08"
    "data-09 data-10";
  @include mq-down(lg) {
    gap: 8rem 4rem;
  }
  @include mq-down(md) {
    grid-template-columns: 1fr;
    gap: 6rem;
    grid-template-areas:
      "data-01"
      "data-02"
      "data-03"
      "data-04"
      "data-05"
      "data-06"
      "data-07"
      "data-08"
      "data-09"
      "data-10";
  }
}

.data__unit {

  &:nth-child(1) {
    grid-area: data-01;
  }

  &:nth-child(2) {
    grid-area: data-02;
  }

  &:nth-child(3) {
    grid-area: data-03;
  }

  &:nth-child(4) {
    grid-area: data-04;
  }

  &:nth-child(5) {
    grid-area: data-05;
  }

  &:nth-child(6) {
    grid-area: data-06;
  }

  &:nth-child(7) {
    grid-area: data-07;
  }

  &:nth-child(8) {
    grid-area: data-08;
  }

  &:nth-child(9) {
    grid-area: data-09;
  }

  &:nth-child(10) {
    grid-area: data-10;
  }

  &:nth-child(11) {
    grid-area: data-11;
  }
}

.data__unit_contentWrap {
  display: flex;
}

.data__unit_content {
  display: flex;
  gap: clamp_size(30,60,992,1440);
  align-items: center;
  justify-content: center;
  min-height: 30rem;
  margin-top: 4rem;
  padding: 5rem;
  background-color: $clr_l_yellow;
  @include mq-down(lg) {
    flex-direction: column;
    gap: 4rem;
    padding: 2rem;
    min-height: 40rem;
    justify-content: flex-start;
  }
  @include mq-down(md) {
    min-height: auto;
    gap: 2rem;
  }

  &--wide {
    gap: clamp_size(40,140,992,1440);
  }

  &--column {
    @include mq-down(xl) {
      flex-direction: column;
    }
    .data__unit_states {
      gap: 1.2rem 2.4rem;
      grid-template-columns: repeat(2, 1fr);
    }

    .data__unit_state {
      .icon {
        width: 8px;
        height: 8px;
      }
    }
  }
}

.data__unit_wrap {
  display: flex;
  gap: clamp_size(20,60,992,1440);
  align-items: center;
  justify-content: center;
  @include mq-down(lg) {
    gap: 6rem;
  }
  @include mq-down(md) {
    gap: clamp_size(30,50,576,768);
  }
  @include mq-down(sm) {
    flex-direction: column;
    gap: 4rem;
  }
}

.data__unit_graph {
  width: clamp_size(140,200,992,1440);
  height: clamp_size(140,200,992,1440);

  &--bar {
    width: clamp_size(270,300,576,1440);
    height: 24rem;
    padding: 1.5rem;
    background-color: #fff;
    @include mq-down(sm) {
      width: clamp_size(270,300,375,576);
    }
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.data__unit_states {
  display: grid;
  gap: 1.2rem;
}

.data__unit_state {
  display: flex;
  gap: .8rem;
  align-items: center;

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;

    &--pink {
      background-color: $clr_pink;
    }

    &--orange {
      background-color: $clr_orange;
    }

    &--blue {
      background-color: $clr_blue;
    }

    &--navy {
      background-color: $clr_navy;
    }

    &--green {
      background-color: $clr_green;
    }

    &--darkgreen {
      background-color: $clr_d_green;
    }

    &--purple {
      background-color: $clr_purple;
    }

    &--gray {
      background-color: $clr_b_gray;
    }

    &--red {
      background-color: $clr_red;
    }
  }

  .num {
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.data__unit_genders {
  display: grid;
  gap: 2rem;

  .data__unit_num {
    width: clamp_size(200,240,992,1440);
    @include mq-down(lg) {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

.data__unit_other {
  .data__unit_num {
    width: 21rem;
    @include mq-down(lg) {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

.data__unit_num {
  display: flex;
  align-items: flex-end;
  font-size: clamp_size(40, 60);
  font-family: $fnt_kaisei;
  font-weight: 700;

  .sex {
    font-size: clamp_size(20, 24);
    &--men {
      color: $clr_blue;
    }

    &--woman {
      color: $clr_red;
    }
  }

  .num {
    line-height: 1;
    margin-left: 1rem;
  }

  .unit {
    font-size: clamp_size(14,18);
    margin-left: .5rem;
  }
}

.data__unit_caption {
  margin-top: 3rem;

  p {
    font-size: clamp_size(11, 12);
    font-weight: 700;
  }
}

.data__unit_obj {
  @include mq-down(sm) {
    text-align: center;
  }

  img {
    @include mq-down(sm) {
      width: 70%;
    }
  }
}