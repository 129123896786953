@charset "utf-8";

///////////////////////////////////////////////////////////
// about
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// about__beliefs
// ---------------------------------------------------------
.about__beliefs {
  .c-sectionTitle {
    text-align: center;
  }
}

.about__beliefs_list {
  display: grid;
  gap: clamp_size(30, 50);
  grid-template-columns: repeat(2, 1fr);
  margin-top: clamp_size(40, 60);
  @include mq-down(md) {
    grid-template-columns: 1fr;
  }
}

.about__beliefs_item {
  text-align: center;
  background-color: #fff;
  padding: 6rem 4%;
  border-radius: 5px;
}

.about__beliefs_item_copy {
  font-size: 2.8rem;
  font-weight: 700;
  font-family: $fnt_kaisei;
  color: $clr_blue;
  margin-top: calc(10/28*1em);
}

.about__beliefs_item_desc {
  margin-top: calc(20/17*1em);
}

// ---------------------------------------------------------
// about__message
// ---------------------------------------------------------
.about__message_inner {
  display: flex;
  justify-content: space-between;
  margin-top: clamp_size(20, 40);
  @include mq-down(lg) {
    gap: 4rem;
    flex-direction: column;
  }
}

.about__message_content {
  width: calc(626/1300*100%);
  line-height: 2.2;
  @include mq-down(lg) {
    width: 100%;
  }
}

.about__message_slider {
  width: calc(628/1300*100%);
  @include mq-down(lg) {
    width: 100%;
    max-width: 62.8rem;
    margin: 0 auto;
  }
}

.about__message_slide {
  img {
    width: 100%;
    aspect-ratio: 628/410;
    object-fit: cover;
    border-radius: 5px;
  }
}

.about__message_slider_button {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  margin-top: clamp_size(15, 20);
  
  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    margin-top: 0;
  }
}

.about__message_name {
  line-height: 2.2;
  margin-top: calc(15/17*1em);
  text-align: right;

  .position,
  .name {
    display: block;
  }
}

.about__message_bg {
  margin-top: clamp_size(80, 160);
}

// ---------------------------------------------------------
// about__history
// ---------------------------------------------------------
.about__history {
  .c-sectionTitle {
    text-align: center;
  }
}

.about__history_unit {
  margin-top: clamp_size(40, 60);

  & + & {
    margin-top: clamp_size(70, 100);
  }
}

.about__history_list {
  margin-top: clamp_size(10, 20);
}

.about__history_wrap {
  display: grid;
  grid-template-columns: 4em 1fr;
  gap: clamp_size(20, 60);
  padding: clamp_size(15, 20) 0;
  border-bottom: 1px solid $clr_l_gray;

  dt,
  dd {
    line-height: 2;
  }
}