@charset "utf-8";

///////////////////////////////////////////////////////////
// ls
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;

.u-ls {
  @for $i from 10 through 30 {
    &#{$i} {
      letter-spacing: calc(#{$i} / 100 * 1em);
    }
  }
}