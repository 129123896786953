@charset "utf-8";

///////////////////////////////////////////////////////////
// footer
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.footer__inner {
  padding: clamp_size(130,170) 0 clamp_size(60, 100);
  background-color: $clr_l_yellow;
  @include mq-down(lg) {
    padding-top: 0;
  }
}

.footer__title {
  text-align: center;
}

.footer__title_link {
  display: inline-block;
  transition: $transition_opacity;

  @include hover {
    opacity: $opacity;
  }
}

.footer__title_name {
  display: block;
  font-size: clamp_size(15, 18);
  font-weight: 700;
  font-family: $fnt_kaisei;
  letter-spacing: .1em;
  margin-top: calc(5/18*1em);
}

// ---------------------------------------------------------
// nav
// ---------------------------------------------------------
.footer__nav {
  margin-top: clamp_size(40, 60);
}

.footer__navList {
  display: grid;
  gap: clamp_size(30,50,992,1440);
  grid-template-columns: repeat(3, auto);
  @include mq-down(xl) {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas:
      "footer-navItem-01 footer-navItem-03"
      "footer-navItem-02 footer-navItem-02";
  }
  @include mq-down(sm) {
    display: flex;
    flex-direction: column;
    grid-template-areas: none;
    gap: 0;
  }
}

.footer__navItem {
  @include mq-down(xl) {
    &:nth-child(2) {
      grid-area: footer-navItem-01;
    }

    &:nth-child(3) {
      grid-area: footer-navItem-02;
    }

    &:nth-child(4) {
      grid-area: footer-navItem-03;
    }
  }

  @include mq-down(sm) {
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 1.5rem;
    }
  }
}

.footer__navLink,
.footer__navParent,
.footer__navTitle {
  display: block;
  font-size: clamp_size(16, 20);
  font-family: $fnt_kaisei;
  font-weight: 700;
  padding-bottom: calc(10/20*1em);
  border-bottom: 1px solid $clr_l_gray;
}

.footer__navLink,
.footer__navParent {
  transition: color .3s ease-in-out;

  @include hover {
    color: $clr_blue;
  }
}

.footer__navParent {
  @include mq-down(sm) {
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(calc(-50% - 3px));
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: $clr_blue;
    }
  
    &::after {
      transform: translateY(calc(-50% - 3px)) rotate(90deg);
      transition: all .3s ease;
    }

    &.is-accordion-open {
      &::after {
        transform: translateY(calc(-50% - 3px)) rotate(0);
      }
    }
  }
}

/* サブリンク
---------------------------------------------------------- */
.footer__navChildListWrap {
  @include mq-down(sm) {
    display: none;
  }
}

.footer__navChildList {
  margin-top: 2rem;
  @include mq-down(sm) {
    margin-bottom: 1rem;
  }

  &--column {
    display: grid;
    gap: 3rem 4rem;
    grid-template-columns: auto auto;
    grid-template-areas:
      "footer-navChildItem-01 footer-navChildItem-02"
      "footer-navChildItem-03 footer-navChildItem-02";
    @include mq-down(md) {
      gap: 3rem;
    }
    @include mq-down(sm) {
      display: flex;
      flex-direction: column;
      grid-template-areas: none;
      gap: 2rem;
    }

    .footer__navChildItem {
      @include mq-down(sm) {
        margin-top: 0 !important;
      }

      &:nth-child(1) {
        grid-area: footer-navChildItem-01;
      }

      &:nth-child(2) {
        grid-area: footer-navChildItem-02;
      }

      &:nth-child(3) {
        grid-area: footer-navChildItem-03;
      }
    }
  }
}

.footer__navChildItem {
  & + & {
    margin-top: 1rem;
  }
}


.footer__navChildLink {
  display: inline-block;
  transition: color .3s ease-in-out;

  @include hover {
    color: $clr_blue;
  }
}

.footer__navChildItem_title {
  font-family: $fnt_kaisei;
  font-weight: 700;
}

.footer__navChildItem_item {
  margin-top: 1rem;
}

/* 矢印付きリンク（3列目）
---------------------------------------------------------- */
.footer__navItem_item {
  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  .footer__navLink {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
  
    &::after {
      content: "";
      display: block;
      width: 22px;
      height: 13px;
      background-image: url(img/icon/icon_arrow_r.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

// ---------------------------------------------------------
// bottom
// ---------------------------------------------------------
.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  @include mq-down(sm) {
    flex-direction: column;
    gap: 1.5rem;
  }
}

.footer__bottom_link {
  .c-outlook {
    transition: color .3s ease-in-out;

    @include hover {
      color: $clr_blue;
    }
  }
}

.footer__bottom_copyright {
  font-size: clamp_size(10, 12);
}