@charset "utf-8";

///////////////////////////////////////////////////////////
// date
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-date {
  display: block;
  font-size: clamp_size(12, 13);
  font-family: $fnt_kaisei;
}