@charset "utf-8";

///////////////////////////////////////////////////////////
// cta
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-cta {
  transform: translateY(50%);
  @include mq-down(lg) {
    transform: translateY(0);
    background-color: $clr_l_yellow;
    padding: 10rem 0;
  }
}

.p-cta__list {
  display: flex;
  justify-content: center;
  gap: clamp_size(30,50,768,1440);
  width: 100%;
  @include mq-down(lg) {
    flex-direction: column;
    background-color: $clr_l_yellow;
    max-width: 62.8rem;
    margin: 0 auto;
  }
}

.p-cta__item {
  width: 50%;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out; 
  @include mq-down(lg) {
    width: 100%;
  }
  
  &--yellow {
    background-color: hsla(43, 100%, 80%, 1);

    @include hover {
      background-color: hsla(43, 100%, 88%, 1);
    }
  }
  
  &--blue {
    background-color: hsla(187, 63%, 66%, 1);

    @include hover {
      background-color: hsla(187, 63%, 74%, 1);
    }
    
    .p-cta__item_ja,
    .p-cta__item_en {
      color: #fff;
    }
  }
}

.p-cta__item_link {
  position: relative;
  display: block;
  padding: clamp_size(20,30,992,1440) clamp_size(40,60,992,1440);
  @include mq-down(lg) {
    padding: clamp_size(15,30,375,992) clamp_size(20,60,375,992);
  }
}

.p-cta__item_ja {
  font-size: clamp_size(20, 28);
  font-family: $fnt_kaisei;
  font-weight: 700;
}

.p-cta__item_en {
  font-size: clamp_size(16, 22);
  font-family: $fnt_mincho;
  font-weight: 700;
}

.p-cta__item_obj {
  position: absolute;
  top: 0;
  right: calc(80/1300*100%);
  transform: translateY(-2rem);
  width: clamp_size(118,138,992,1440);
  @include mq-down(lg) {
    width: clamp_size(98,138,375,992);
  }
  @include mq-down(sm) {
    transform: translateY(-1.5rem);
  }
}

#about .p-cta {
  margin-top: clamp_size(70,100,992,1440);
  @include mq-down(lg) {
    margin-top: clamp_size(80,160);
  }
}