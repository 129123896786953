@charset "utf-8";

///////////////////////////////////////////////////////////
// form
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-form {
  .c-select {
    max-width: 40rem;
  }
}

.p-form__input,
.p-form__textarea {
  font-size: clamp_size(14, 16);
  font-weight: 500;
  line-height: 1;
  width: 100%;
  background-color: $clr_s_gray;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 2rem;
  
  &::placeholder {
    color: #B9B9B9;
  }

  &:focus {
    outline: 1px solid $clr_blue;
  }
}

.p-form__input {
  height: 5rem;
}

.p-form__textarea {
  padding: 1.2em 2rem;
  height: 24.5rem;
  line-height: 1.8;
}

.p-form__inner {

}

.p-form__block {
  padding: clamp_size(15, 25) 0;
  border-top: 1px solid $clr_l_gray;

  &:last-child {
    border-bottom: 1px solid $clr_l_gray;
  }

  &.is-error  {
    input,
    textarea {
      outline: 1px solid $clr_red;
  
      &::placeholder {
        color: rgba(230,111,118,0.7);
      }
    }

    select {
      outline: 1px solid $clr_red;
    }

    input[type=checkbox], input[type=radio] {
      & + span::before {
        border-color: rgba(230, 111, 118, 0.7);
      }
    }

    .p-form__error {
      display: block;
    }
  }
}

.p-form__block_wrap {
  display: grid;
  align-items: center;
  grid-template-columns: clamp_size(186,226,768,1440) 1fr;
  gap: 1rem;
  @include mq-down(md) {
    grid-template-columns: 1fr;
  }

  &--noLine {
    .p-form__label {
      align-self: flex-start;
    }
  }
}

.p-form__label_text {
  font-size: clamp_size(14, 16);
  font-weight: 700;
  cursor: auto;
}

.p-form__label_note {
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  transform: translateX(-0.6em);
}

.p-form__require {
  display: inline-block;
  font-size: clamp_size(14, 16);
  font-weight: 700;
  color: $clr_orange;
  margin-left: .2em;
}

.p-form__error {
  display: none;
}

.p-form__error_text {
  display: flex;
  align-items: center;
  font-size: clamp_size(12, 14);
  font-weight: 500;
  color: $clr_red;
  margin-top: 1rem;

  &::before {
    content: "";
    width: calc(14/14*1em);
    height: calc(14/14*1em);
    mask-image: url("img/icon/icon_error.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: $clr_red;
    margin-right: 0.4em;
  }
}

// ---------------------------------------------------------
// チェックボックス
// ---------------------------------------------------------
.p-form__checkbox {
  font-size: clamp_size(13, 16);
  margin-top: 2.5rem;
}

.p-form__checkbox_input {
  opacity: 0;
  position: absolute;

  + .p-form__checkbox_input_text {
    position: relative;
    display: inline-block;
    padding: .3em .3em .3em 2.5rem;
    font-size: clamp_size(13, 15);
    font-size: 1.5rem;
    line-height: 1;
    cursor: pointer;
    @include mq-down(md) {
      font-size: 1.4rem;
    }
    @include mq-down(sm) {
      font-size: 1.3rem;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background-color: #fff;
      border: 1px solid $clr_d_gray;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      display: none;
    }
  }

  &:focus + .p-form__checkbox_input_text {
    outline: 2px solid $clr_blue;
  }

  &:checked + .p-form__checkbox_input_text::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 6px;
    display: block;
    width: 5px;
    height: 10px;
    transform: rotate(45deg);
    border-bottom: 3px solid $clr_blue;
    border-right: 3px solid $clr_blue;
    @include mq-down(md) {
      top: 3px;
    }
    @include mq-down(sm) {
      top: 2px;
    }
  }
}

// ---------------------------------------------------------
// プライバシーポリシー
// ---------------------------------------------------------
.p-form__privacy_box {
  padding: clamp_size(15, 20);
  border-radius: 5px;
  background-color: $clr_s_gray;
  height: 24rem;
}

.p-form__privacy_box_content {
  height: 100%;
  padding-right: clamp_size(20, 30);
  overflow-y: auto;

  -ms-overflow-style: none;
  /* IE, Edge 対応 */
  scrollbar-width: none;
  /* Firefox 対応 */

  &::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }


  p {
    font-size: clamp_size(13, 15);

    &:not(:first-child) {
      margin-top: 2em;
    }
  }
  ul {
    margin-top: 1.5rem;
  }
  li {
    font-size: clamp_size(13, 15);
  }

  a {
    font-size: clamp_size(13, 15);
    text-decoration: underline;
    text-underline-offset: .3em;

    @include hover {
      text-decoration: none;
    }
  }

  .mt0 {
    margin-top: 0 !important;
  }
}

.simplebar-scrollbar::before {
  background: #959595 !important;
  border-radius: 10px;
  width: 8px;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

.simplebar-track {
  background: #E6E6E6;
  width: 8px !important;
  margin-top: 0;
  border-radius: 10px;
}

// ---------------------------------------------------------
// ボタン
// ---------------------------------------------------------
.p-form__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp_size(30, 50);
}

.p-form__button_confirm {
  font-size: clamp_size(13, 15);
  text-decoration: underline;
  text-underline-offset: .3em;
  margin-top: calc(20/15*1em);

  @include hover {
    text-decoration: none;
  }
}