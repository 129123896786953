@charset "utf-8";

///////////////////////////////////////////////////////////
// category
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-category {
  display: inline-block;
  font-size: clamp_size(12,13);
  font-weight: 700;
  padding: .36em 1.23em;
  border-radius: 3rem;
  background-color: hsla(187, 63%, 66%, 1);
  transition: background-color .3s ease-in-out;
  @include linkColor(#fff);
  @include hover {
    background-color: hsla(187, 63%, 74%, 1);
  }
}