@charset "utf-8";

///////////////////////////////////////////////////////////
// error
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.error {
  text-align: center;
}

.error__text {
  margin-top: calc(40/17*1em);
}

.error__link {
  margin-top: 4rem;

  .c-button {
    width: fit-content;
  }
}