@charset "utf-8";

///////////////////////////////////////////////////////////
// btn
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-button {
  position: relative;
  display: inline-block;
  font-size: clamp_size(13, 16);
  font-weight: 700;
  padding: calc(14/16*1em) calc(48/16*1em);
  text-align: center;
  width: fit-content;
  max-width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  transition: all .3s ease;
  @include linkColor(#fff);

  > span {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  &--blue {
    background-color: hsla(187, 63%, 66%, 1);

    &.c-button--arrow {
      .c-circleArrow {
        &::after {
          background-color: $clr_blue;
        }
      }
    }

    @include hover {
      background-color: hsla(187, 63%, 74%, 1);
    }
  }

  &--orange {
    background-color: hsla(36, 100%, 62%, 1);

    &.c-button--arrow {
      .c-circleArrow {
        &::after {
          background-color: $clr_orange;
        }
      }
    }

    @include hover {
      background-color: hsla(36, 100%, 74%, 1);
    }
  }

  &--arrow {
    position: relative;
    padding: calc(14/16*1em) calc(58/16*1em) calc(14/16*1em) calc(48/16*1em);

    .c-circleArrow {
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      width: 2.3rem;
      height: 2.3rem;
      background-color: #fff;

      &::after {
        width: 10px;
        height: 5px;
      }
    }
  }
}