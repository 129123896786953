@charset "utf-8";

///////////////////////////////////////////////////////////
// single
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.news__container {
  display: grid;
  grid-template-columns: 1fr clamp_size(280,400,992,1440);
  gap: clamp_size(30,50,992,1440);
  @include mq-down(lg) {
    gap: 6rem;
    grid-template-columns: 1fr;
  }
}

.news__list {
  display: grid;
  gap: clamp_size(30,50,992,1440);
  grid-template-columns: repeat(2, 1fr);
  @include mq-down(sm) {
    grid-template-columns: 1fr;
  }
}

.news__item {
  display: contents;
}

.news__item_link {  
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;
  gap: 0;
  border-radius: 5px;
  border: 1px solid $clr_blue;

  @include hover {
    .news__item_thumb {
      img {
        transform: scale(1.02);
      }
    }
  }
}

.news__item_thumb {
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 402/287;
    border-radius: 5px;
    transition: transform .3s ease-in-out;
  }
}

.news__item_body {
  display: grid;
  gap: 1rem;
  grid-template-rows: auto auto 1fr;
  padding: 2rem;
}

.news__item_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news__item_title {
  font-weight: 700;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

// ---------------------------------------------------------
// single
// ---------------------------------------------------------
.news__head {

}

.news__head_info {
  display: flex;
  align-items: center;
  gap: 2rem;

  .c-date {
    color: #AAAAAA;
  }
}

.news__head_heading {
  font-size: clamp_size(24, 32);
  font-weight: 700;
  line-height: 1.4;
  margin-top: calc(22/32*1em);
}

// ---------------------------------------------------------
// content
// ---------------------------------------------------------
.content {
  > * {
    margin-top: 2em;
  }

  > *:first-child {
    margin-top: clamp_size(40, 60);
  }

  h2 {
    font-size: clamp_size(20, 28);
    font-weight: 700;
    line-height: 1.6;
    color: $clr_blue;
    margin-bottom: .4em;

    & + * {
      margin-top: 0;
    }
  }

  h3 {
    position: relative;
    font-size: clamp_size(22, 24);
    font-weight: 700;
    line-height: 1.6;
    padding-left: calc(16/24*1em);
    margin-bottom: .4em;

    & + * {
      margin-top: 0;
    }

  
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(calc(-50% + clamp_size(1, 2)));
      transform: translateY(-50%);
      height: 80%;
      width: 5px;
      background-color: $clr_blue;
    }

  }

  h4,h5,h6 {
    font-weight: 700;
    line-height: normal;
    color: $clr_blue;
    margin-bottom: .4em;
    line-height: 1.6;

    & + * {
      margin-top: 0;
    }
  }

  h4 {
    font-size: clamp_size(20, 24);
  }

  h5 {
    font-size: clamp_size(18, 20);
  }

  h6 {
    font-size: clamp_size(14, 17);
  }

  p {
    line-height: 1.8;

    & + p,
    & + a {
      margin-top: 1.25em;
    }
  }

  strong {
    font-size: inherit;
    font-family: inherit;
    font-weight: 700;
  }

  ol {
    list-style-type: none;
    counter-reset: item;

    & + ul,
    & + ol {
      margin-top: clamp_size(20, 40);
    }

    li {
      position: relative;
      padding-left: 1.5em;
      line-height: 1.6;
      
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        counter-increment: item;
        content: counter(item)'.';
        font-size: clamp_size(14, 17);
        font-weight: 700;
        color: $clr_black;
      }

      &:not(:first-child) {
        margin-top: .5em;
      }
    }
  }
  
  ul {
    list-style: none;

    & + ul,
    & + ol {
      margin-top: clamp_size(20, 40);
    }
    
    li {
      display: block grid;
      grid-template-columns: auto 1fr;
      column-gap: 1em;
    
      &::before {
        content: "";
        width: 1rem;
        height: 1rem;
        margin-block: calc((1lh - 1em) / 2);
        border-radius: 50%;
        background-color: $clr_l_blue;
        transform: translateY(clamp_size(2, 3));
      }

      &:not(:first-child) {
        margin-top: .5em;
      }
    }
  }

  a {
    font-weight: 700;
    color: $clr_blue;
    text-decoration: underline;
    word-break: break-all;
    text-underline-offset: 0.2em;

    & + p,
    & + a {
      margin-top: 2rem;
    }

    & + * {
      margin-top: clamp_size(20, 40);
    }

    @include hover {
      text-decoration: none;
    }
  }

  blockquote {
    position: relative;
    margin: clamp_size(40, 60) 0 !important;
    padding: clamp_size(30, 50) clamp_size(50, 75);
    background-color: $clr_l_yellow;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: clamp_size(22, 32);
      height: clamp_size(20, 28);
      background-size: cover;
      background-repeat: no-repeat;
    }

    &::before {
      top: clamp_size(30, 45);
      left: clamp_size(15, 20);
      background-image: url(img/icon/icon_blockquote_l.svg);
    }

    &::after {
      bottom: clamp_size(30, 45);
      right: clamp_size(10, 20);
      background-image: url(img/icon/icon_blockquote_r.svg);
    }

    cite {
      display: block;
      font-size: 1.2rem;
      margin-top: 1em;
    }

    & + * {
      margin-top: 4rem;
    }
  }

  .wp-block-table {
    width: 100%;
    padding-bottom: 1.5rem;
    overflow-x: scroll;

    & + * {
      margin-top: 4rem;
    }
  }

  table {
    min-width: 82.5rem;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    overflow-x: auto;

    thead {
      border-bottom: none;
    }
    
    tr {
      background-color: transparent !important;
    }
    
    th,
    td {
      font-size: clamp_size(14, 17);
      padding: 1.2em 1.35em;
      word-break: break-word;
      border: 1px solid $clr_blue;
      box-sizing: border-box;

      small {
        display: block;
        font-size: clamp_size(14, 15);
        line-height: 1.6;
        margin-top: .5rem;
      }
    }
    
    th {
      font-weight: 700;
      text-align: left;
    }

    a {
      font-size: clamp_size(14, 17);
      color: $clr_blue;
      line-height: normal;
      word-break: break-all;
      text-decoration: none;
      text-underline-offset: 0.3em;
      
      @include hover {
        text-decoration: underline;
      }
    }
  }

  .wp-block-post-featured-image {
    margin-bottom: clamp_size(30, 50);
  }
}

.news__button {
  margin-top: 6rem;

  .p-circleLink_text {
    font-family: $fnt_gothic;
  }
}
