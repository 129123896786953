@charset "utf-8";

///////////////////////////////////////////////////////////
// circleLink
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-circleLink {
  display: flex;
  align-items: center;
  gap: 1.4rem;

  @include hover {
    .p-circleLink_text {
      color: $clr_blue;
    }

    .p-circleLink_icon {
      &::after {
        animation: circleArrowAnimation .6s cubic-bezier(.23,1,.32,1);
    
        @keyframes circleArrowAnimation {
          0% {
            transform: translate(0);
            opacity: 1;
          }
          49.9% {
            transform: translate(100%);
            opacity: .01;
          }
          50% {
            transform: translate(-100%);
            opacity: .01;
          }
          100% {
            transform: translate(0);
            opacity: 1;
          }
        }
      }
    }
  }

  &--white {
    .p-circleLink_text {
      color: #fff;
    }

    .p-circleLink_icon {
      background-color: #fff;
    }

    @include hover {
      .p-circleLink_text {
        color: #fff;
      }
    }
  }

  &--blue {
    .p-circleLink_icon {
      background-color: $clr_blue;

      &::after {
        background-color: #fff;
      }
    }
  }

  &--border {
    .p-circleLink_icon {
      border: 1px solid $clr_blue;
    }
  }

  &--left {
    .p-circleLink_icon {
      &::after {
        mask-image: url("img/icon/icon_arrow_l.svg");
      }
    }

    @include hover {
      .p-circleLink_icon {
        &::after {
          animation: circleArrowLeftAnimation .6s cubic-bezier(.23,1,.32,1);
      
          @keyframes circleArrowLeftAnimation {
            0% {
              transform: translate(0);
              opacity: 1;
            }
            49.9% {
              transform: translate(-100%);
              opacity: .01;
            }
            50% {
              transform: translate(100%);
              opacity: .01;
            }
            100% {
              transform: translate(0);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.p-circleLink_text {
  font-size: clamp_size(16, 20);
  font-family: $fnt_mincho;
  font-weight: 600;
  transition: color .3s ease-in-out;
}

.p-circleLink_icon {
  display: grid;
  place-content: center;
  width: clamp_size(36, 46);
  height: clamp_size(36, 46);
  border-radius: 50%;

  &::after {
    content: "";
    display: block;
    width: 17px;
    height: 9px;
    mask-image: url("img/icon/icon_arrow_r.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: $clr_blue;
    z-index: 1;
  }
}