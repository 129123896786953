@charset "utf-8";

///////////////////////////////////////////////////////////
// aside
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-aside {
  @include mq-down(lg) {
    max-width: 108rem;
    margin: 0 auto;
  }
}

.p-aside__content {
  & + & {
    margin-top: clamp_size(30,50);
  }
}

.p-aside__title {
  position: relative;
  font-size: clamp_size(30, 50);
  font-family: $fnt_mincho;
  color: $clr_blue;
  line-height: 1;
  padding-bottom: .2em;
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $clr_blue;
  }
}

.p-aside__categories {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  margin-top: 2.4rem;
}

.p-aside__archive {
  margin-top: 2.4rem;
}