@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// 余白関連
// .u-m--X = margin, .u-p--X = padding
// .u-m--5 や .u-p--t5 のように使う 数字 1 = 1px = .1rem
@for $i from 1 through 30 {  
  // .5rem ~ 15rem まで生成
  .u-m {
    &-#{$i * 5} {
      margin: #{$i * 0.5}rem !important;
    }

    &-t#{$i * 5} {
      margin-top: #{$i * 0.5}rem !important;
    }

    &-b#{$i * 5} {
      margin-bottom: #{$i * 0.5}rem !important;
    }

    &-l#{$i * 5} {
      margin-left: #{$i * 0.5}rem !important;
    }

    &-r#{$i * 5} {
      margin-right: #{$i * 0.5}rem !important;
    }
  }

  .u-p {
    &-#{$i * 5} {
      padding: #{$i * 0.5}rem !important;
    }

    &-t#{$i * 5} {
      padding-top: #{$i * 0.5}rem !important;
    }

    &-b#{$i * 5} {
      padding-bottom: #{$i * 0.5}rem !important;
    }

    &-l#{$i * 5} {
      padding-left: #{$i * 0.5}rem !important;
    }

    &-r#{$i * 5} {
      padding-right: #{$i * 0.5}rem !important;
    }
  }
}

/* clamp */
@for $i from 1 through 50 {
  $margin: $i;
  $padding: $i;
  
  .u-m {
    &-cl-#{$i} {
      margin: clamp_size($margin, $margin * 2) !important;
    }

    &-cl-t#{$i} {
      margin-top: clamp_size($margin, $margin * 2) !important;
    }

    &-cl-b#{$i} {
      margin-bottom: clamp_size($margin, $margin * 2) !important;
    }

    &-cl-l#{$i} {
      margin-left: clamp_size($margin, $margin * 2) !important;
    }

    &-cl-r#{$i} {
      margin-right: clamp_size($margin, $margin * 2) !important;
    }
  }

  .u-p {
    &-cl-#{$i} {
      padding: clamp_size($padding, $padding * 2) !important;
    }

    &-cl-t#{$i} {
      padding-top: clamp_size($padding, $padding * 2) !important;
    }

    &-cl-b#{$i} {
      padding-bottom: clamp_size($padding, $padding * 2) !important;
    }

    &-cl-l#{$i} {
      padding-left: clamp_size($padding, $padding * 2) !important;
    }

    &-cl-r#{$i} {
      padding-right: clamp_size($padding, $padding * 2) !important;
    }
  }
}