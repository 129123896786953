@charset "utf-8";

///////////////////////////////////////////////////////////
// recruitment
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// flow
// ---------------------------------------------------------
.recruitment__anchor {
  padding-top: 20rem;
  margin-top: -20rem;
}

.recruitment__flow {
  .c-heading {
    text-align: center;
  }
}

.recruitment__flow_list {
  display: grid;
  gap: clamp_size(20,50,992,1440);
  grid-template-columns: repeat(4, 1fr);
  margin-top: clamp_size(30, 40);
  @include mq-down(lg) {
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(sm) {
    grid-template-columns: 1fr;
  }
}

.recruitment__flow_item {
  position: relative;
  padding: 2rem;
  background-color: $clr_l_yellow;

  &:nth-child(3) {
    .recruitment__flow_item_line {
      @include mq-down(lg) {
        display: none;
      }
      @include mq-down(sm) {
        display: block;
      }
    }
  }
}

.recruitment__flow_thumb {
  text-align: center;
}

.recruitment__flow_title {
  font-size: clamp_size(18, 22);
  font-weight: 700;
  font-family: $fnt_kaisei;
  color: $clr_blue;
  text-align: center;
  margin-top: calc(15/22*1em);
}

.recruitment__flow_text {
  line-height: 1.8;
  margin-top: calc(5/17*1em);
  @include mq-down(sm) {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.recruitment__flow_item_line {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 1px;
  background-color: $clr_blue;
  z-index: -1;
  @include mq-down(sm) {
    width: 1px;
    height: 50%;
    top: 0;
    left: 50%;
  }
}

// ---------------------------------------------------------
// links
// ---------------------------------------------------------
.recruitment__links {
  display: grid;
  gap: clamp_size(20,50,992,1440);
  grid-template-columns: repeat(2, 1fr);
  @include mq-down(lg) {
    grid-template-columns: 1fr;
  }
}

// ---------------------------------------------------------
// info
// ---------------------------------------------------------
.recruitment__info {
  margin-top: clamp_size(70, 100);
  border-bottom: 1px solid $clr_l_gray;
}

.recruitment__info_wrap {
  display: grid;
  gap: clamp_size(40, 80);
  grid-template-columns: 9em 1fr;
  border-top: 1px solid $clr_l_gray;
  padding: 2rem 0;
  @include mq-down(sm) {
    gap: 1rem;
    grid-template-columns: 1fr;
  }

  dt,
  dd {
    line-height: 2;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    strong {
      font-size: clamp_size(14, 17);
      font-weight: 700;
    }

    a {
      word-wrap:break-word;
      text-decoration: underline;
      text-underline-offset: .2em;

      @include hover {
        text-decoration: none;
      }
    }
  }
}

// ---------------------------------------------------------
// recruitment__buttons
// ---------------------------------------------------------
.recruitment__buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 5rem;
  @include mq-down(md) {
    flex-direction: column;
  }
}

.recruitment__button {
  @include mq-down(md) {
    max-width: 30rem;
    margin: 0 auto;
  }
}