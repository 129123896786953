@charset "utf-8";

///////////////////////////////////////////////////////////
// caution
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-caution {
  position: relative;
  padding-left: 1.5em;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "※";
    font-size: inherit;
  }
}